<template>
  <div class="detailproject__content--wrapper">
    <div class="detailproject__content--container">
      <div class="detailproject__content--content">
        <div class="detailproject__content--desciption">
          <img :src="data?.image[1]" alt="avatar" v-if="data?.image" />
          <div v-for="(item, indx) in data.content" :key="indx">
            <div class="w-100">
              <h4>{{ item?.title }}</h4>
              <p>{{ item?.content }}</p>
            </div>
          </div>
        </div>
        <div class="detailproject__content--info">
          <h4>{{ label }}</h4>
          <img :src="data?.avatar" alt="avatar" />
          <div v-if="data.pic" class="detailproject__content--pic">
            <p>{{ data?.pic }}</p>
          </div>
          <div>
            <p>{{ data?.message }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ContentProjectDetail',
  data() {
    return {
      label: this.$t('project_detail').shareLabel,
    };
  },
  props: {
    data: Object,
  },
  watch: {
    lang() {
      this.label = this.$t('project_detail').shareLabel;
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
};
</script>

<style lang="scss" scroped>
.detailproject__content--wrapper {
  padding: 50px 0;
  .detailproject__content--container {
    width: 80%;
    margin: 0 auto;
    font-size: 18px;
  }
  .detailproject__content--content {
    display: flex;
    gap: 50px;
  }
  .detailproject__content--desciption {
    flex: 2;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    p {
      white-space: pre-line;
    }
    img {
      width: 100%;
      object-fit: contain;
      max-height: 500px;
    }
  }

  .detailproject__content--info {
    margin-top: 100px;
    flex: 0.75;
    max-width: 400px;
    padding: 50px 50px;
    height: min-content;
    background: #fff6f1;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    h4 {
      color: var(--blue-text);
      font-weight: 700;
      text-align: center;
    }
    p {
      white-space: pre-line;
    }
    .detailproject__content--pic {
      width: 100%;
      border-bottom: 2px solid var(--orange);
      p {
        font-size: 20px;
        font-weight: 600;
        color: var(--orange);
        text-align: center;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 50px 0;
    .detailproject__content--content {
      flex-direction: column;
      align-items: center;
    }
    .detailproject__content--desciption {
      padding: 0;
    }
    .detailproject__content--info {
      margin-top: 0;
    }
  }
  @media (max-width: 768px) {
    margin-top: 50px;
    .detailproject__content--info {
      width: 100%;
      padding: 30px;
    }
  }
}
</style>
