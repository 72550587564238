<template>
  <div class="banner__detailproject">
    <div class="banner__container">
      <img
        class="banner__detailproject__img"
        :src="data?.image[0]"
        v-if="data.image"
        alt="banner"
      />

      <div class="banner__content">
        <span>{{ data.category }}</span>
        <h2>
          {{ data.title }}
        </h2>
      </div>
      <div class="banner__detailproject__tech">
        <!-- <span style="width: max-content; margin-right: 8px">{{
          project_detail.technical
        }}</span> -->
        <span>{{ data.tech }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'BannerProjectDetail',
  data() {
    return {
      about_us: this.$t('about_us'),
      project_detail: this.$t('project_detail'),
      bannerImg: [
        '/images/icons/home/django.svg',
        '/images/icons/home/dotNet.svg',
        '/images/icons/home/docker.svg',
        '/images/icons/home/figma.svg'
      ]
    };
  },
  props: {
    data: Object
  },

  watch: {
    lang() {
      this.about_us = this.$t('about_us');
      this.project_detail = this.$t('project_detail');
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  }
};
</script>

<style lang="scss" scroped>
.banner__detailproject {
  width: 100%;
  background: #001b66;
  height: 400px;
  padding-top: 80px;
  .banner__container {
    display: flex;
    align-items: center;
    width: 90%;
    height: 100%;
    margin-left: auto;
    color: #fff;
    position: relative;
  }
  .banner__detailproject__img {
    position: absolute;
    background: #dee2e6;
    top: 0px;
    right: 0;
    clip-path: polygon(35% 0, 100% 0, 100% 100%, 35% 100%, 0 50%);
    height: 120%;
    width: auto;
    object-fit: contain;
  }
  .banner__detailproject__tech {
    padding: 16px 40px;
    background-color: #edf7ff;
    position: absolute;
    bottom: -30px;
    left: 0%;
    display: flex;
    align-items: center;
    box-shadow: rgb(50 50 93 / 25%) 0px 2px 5px -1px,
      rgb(0 0 0 / 30%) 0px 1px 3px -1px;
    gap: 20px;
    border-radius: 20px;
    span {
      font-size: 18px;
      color: var(--grey-text);
      font-weight: 700;
    }
    .banner__detailproject__tech--item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background-color: #fff;
      img {
        width: 50px;
        height: 50px;
        object-fit: contain;
      }
    }
  }
  .banner__content {
    max-width: 50%;
    h2 {
      position: relative;
      font-weight: 700;
      font-size: 40px;
      color: #fff;
      max-width: 800px;
      z-index: 0;
      margin-bottom: 25px;
    }
    p {
      color: #fff;
      font-size: 18px;
      max-width: 650px;
    }
  }

  @media (max-width: 1024px) {
    height: 300px;
    .banner__container {
      max-width: 100%;
      flex-direction: column;
      padding-top: 5%;
      justify-content: space-between;
      margin: auto;
    }
    .banner__detailproject__img {
      display: none;
    }
    .banner__content {
      max-width: 100%;
    }
    .banner__detailproject__tech span {
      text-align: center;
    }
    .banner__detailproject__tech {
      position: unset;
      padding: 10px 20px;
      width: 100%;
      flex-wrap: wrap;
      justify-content: center;
      gap: 0;
    }
  }
  @media (max-width: 768px) {
    .banner__content h2 {
      font-size: 32px;
      margin-bottom: 8px;
    }
  }
}
</style>
