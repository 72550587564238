var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container",staticStyle:{"max-height":"max-content"},attrs:{"id":"form-mail"}},[_c('form',{ref:"entryForm",staticClass:"form_container",attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.sendEmail.apply(null, arguments)}}},[_c('div',{staticClass:"form-row"},[_c('label',[_vm._v(_vm._s(_vm.dataMail.name))]),_c('div',{staticClass:"input-data"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],ref:"refInput",class:{
          errorBorder: _vm.errors.name,
        },attrs:{"type":"text","placeholder":_vm.dataMail.name},domProps:{"value":(_vm.name)},on:{"blur":_vm.onBlur,"input":function($event){if($event.target.composing)return;_vm.name=$event.target.value}}}),(_vm.errors.name)?_c('p',{staticClass:"danger"},[_vm._v(_vm._s(_vm.errors.name))]):_vm._e()])]),_c('div',{staticClass:"form-row"},[_c('label',[_vm._v(_vm._s(_vm.dataMail.email))]),_c('div',{staticClass:"input-data"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],ref:"emailInput",class:{
          errorBorder: _vm.errors.email,
        },attrs:{"placeholder":_vm.dataMail.email},domProps:{"value":(_vm.email)},on:{"change":_vm.onBlur,"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value}}}),(_vm.errors.email)?_c('p',{staticClass:"danger"},[_vm._v(_vm._s(_vm.errors.email))]):_vm._e()])]),_c('div',{staticClass:"group_row"},[_c('div',{staticClass:"form-row"},[_c('label',[_vm._v(_vm._s(_vm.dataMail.birthday))]),_c('div',{staticClass:"input-data"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.birthday),expression:"birthday"}],class:{
            errorBorder: _vm.errors.birthday,
          },attrs:{"type":"date","placeholder":_vm.dataMail.birthday},domProps:{"value":(_vm.birthday)},on:{"blur":_vm.onBlur,"input":function($event){if($event.target.composing)return;_vm.birthday=$event.target.value}}}),(_vm.errors.birthday)?_c('p',{staticClass:"danger"},[_vm._v(_vm._s(_vm.errors.birthday))]):_vm._e()])]),_c('div',{staticClass:"form-row"},[_c('label',[_vm._v(_vm._s(_vm.dataMail.phone))]),_c('div',{staticClass:"input-data"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],class:{
            errorBorder: _vm.errors.phone,
          },attrs:{"type":"text","placeholder":_vm.dataMail.phone},domProps:{"value":(_vm.phone)},on:{"blur":_vm.onBlur,"input":function($event){if($event.target.composing)return;_vm.phone=$event.target.value}}}),(_vm.errors.phone)?_c('p',{staticClass:"danger"},[_vm._v(_vm._s(_vm.errors.phone))]):_vm._e()])])]),_c('div',{staticClass:"form-row"},[_c('label',[_vm._v(_vm._s(_vm.dataMail.skills))]),_c('div',{staticClass:"input-data",style:({
        height: 'unset',
      })},[_c('div',{staticClass:"multiselect__custom"},[_c('multiselect',{class:{
              errorBorder: _vm.errors.selectedSkills,
            },staticStyle:{"width":"100%"},attrs:{"options":_vm.skills,"multiple":true,"searchable":false,"close-on-select":false,"show-labels":false,"placeholder":"Skills","label":"name","track-by":"name"},on:{"select":_vm.onBlur},model:{value:(_vm.selectedSkills),callback:function ($$v) {_vm.selectedSkills=$$v},expression:"selectedSkills"}}),_c('input',{staticStyle:{"width":"0","height":"0","padding":"0","border-color":"transparent","outline":"transparent","position":"absolute","left":"50%","transform":"translateY(50%)"},domProps:{"value":_vm.selectedSkills}}),(_vm.errors.selectedSkills)?_c('p',{staticClass:"danger"},[_vm._v(" "+_vm._s(_vm.errors.selectedSkills)+" ")]):_vm._e()],1)])]),_c('div',{staticClass:"form-row"},[_c('label',[_vm._v(_vm._s(_vm.dataMail.message))]),_c('div',{staticClass:"input-data textarea"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.yourMessage),expression:"yourMessage"}],attrs:{"rows":"4","cols":"50","placeholder":_vm.dataMail.message},domProps:{"value":(_vm.yourMessage)},on:{"input":function($event){if($event.target.composing)return;_vm.yourMessage=$event.target.value}}}),_c('br'),_c('br')])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"input-data"},[_c('div',{staticClass:"btn-cv",on:{"click":_vm.onButtonClick}},[_vm._v(" "+_vm._s(this.files ? this.files.name.length > 9 ? this.files.name.slice(0, 10) + '...' : this.files.name : this.upload)+" ")]),(this.files)?_c('div',{staticClass:"btn-close",on:{"click":_vm.clearFiles}},[_vm._v("+")]):_vm._e(),_c('input',{ref:"uploader",staticClass:"d-none",class:{
            errorBorder: _vm.errors.files,
          },attrs:{"type":"file","accept":".pdf,.doc,.docx"},domProps:{"value":null},on:{"change":_vm.onFileChanged}}),(_vm.errors.files)?_c('p',{staticClass:"danger"},[_vm._v(_vm._s(_vm.errors.files))]):_vm._e()])]),_c('div',{staticClass:"content-cv"},[_vm._v(_vm._s(_vm.dataMail.content_cv))]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"input-data"},[_c('div',{staticClass:"btn-cv",on:{"click":_vm.onButtonOtherClick}},[_vm._v(" "+_vm._s(_vm.dataMail.other_file)+" ")]),_c('input',{ref:"otherUploader",staticClass:"d-none",attrs:{"type":"file","multiple":""},on:{"change":_vm.onFilesChange}})]),(_vm.otherFiles?.length > 0)?_c('div',{staticClass:"list-other-file"},[_c('span',[_vm._v(_vm._s(_vm.dataMail.other_file_list))]),_c('ul',_vm._l((_vm.otherFiles),function(file,index){return _c('li',{key:index},[_c('span',[_vm._v(_vm._s(file.name))]),_c('span',{staticClass:"btn-delete-file",on:{"click":function($event){return _vm.removeFile(file.name)}}},[_vm._v("["+_vm._s(_vm.dataMail.delete)+"]")])])}),0)]):_vm._e()]),_c('div',{staticClass:"form-row submit-btn"},[_c('button',{staticClass:"btn-submit",attrs:{"disabled":_vm.loadingSubmit,"type":"submit"}},[_vm._v(" "+_vm._s(_vm.loadingSubmit ? 'Loading' : _vm.dataMail.submit)+" ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }