<template>
  <div class="projects__orther">
    <div class="projects__block">
      <div class="projects__content">
        <h1 class="projects__content__title">{{ header.projectsOther }}</h1>
      </div>
    </div>

    <div class="projects__slider">
      <!-- <Carousel :data="data" :arrowCenterOptional="true" /> -->
      <Carousel2 :data="data" :arrowCenterOptional="true" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
// import Carousel from "../Components/Carousel.vue";
import Carousel2 from '../Components/Carousel2.vue';

export default {
  name: 'ListOrtherProject',
  data() {
    return {
      arrowRight: '/images/icons/home/arrow-right.svg',
      arrowLeft: '/images/icons/home/arrow-left.svg',
      button: this.$t('button'),
      header: this.$t('header'),
      projects: this.$t('project'),
      projectContent: this.$t('home').project,
    };
  },
  props: {
    data: Array,
  },
  watch: {
    lang() {
      this.header = this.$t('header');
      this.button = this.$t('button');
      this.projects = this.$t('project');
      this.projectContent = this.$t('home').project;
    },
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
    }),
  },
  mounted() {},
  components: { Carousel2 },
};
</script>
<style lang="scss" scroped>
img {
  object-fit: cover;
}

.projects__orther {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin: 0 auto;
  padding: 50px 0;
  gap: 60px;

  .projects__block {
    display: flex;
    justify-content: center;
  }
  .projects__content {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  .projects__content__title {
    position: relative;
    font-weight: 700;
    font-size: 40px;
    color: var(--blue-text);
    z-index: 0;
    &::after {
      content: '';
      position: absolute;
      background: url('/images/bg/circle-title.svg');
      background-repeat: no-repeat;
      background-size: cover;
      width: 38px;
      height: 38px;
      z-index: -1;
      top: 0;
      left: -18px;
    }
  }
  .projects__content__sub {
    color: var(--grey-text);
    font-size: 18px;
  }
  .projects__detail {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .projects__detail__item {
    display: flex;
    align-items: center;
    gap: 28px;
  }
  .projects__detail__number {
    font-size: 80px;
    line-height: 80px;
    font-weight: 700;
    color: var(--blue-text);
  }
  .projects__detail__descripe {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    span {
      font-size: 44px;
      font-weight: 700;
      color: var(--orange);
    }
    h4 {
      font-size: 24px;
      font-weight: 400;
      color: var(--grey-text);
    }
  }
  .projects__view-btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    width: fit-content;
    padding: 12px 24px;
    background-color: var(--orange);
    color: #fff;
    font-size: 18px;
    border-radius: 25px;
    cursor: pointer;
  }
  .projects__container {
    display: flex;
    align-items: flex-start;
    gap: 70px;
    height: 100%;
  }
  .projects__slider {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 1024px) {
  .projects__orther {
    flex-direction: column;
    gap: 24px;
    padding-top: 100px;
    margin: 0 auto;
    .projects__block {
      gap: 16px;
    }
    .projects__detail {
      gap: 10px;
    }
    .projects__detail__number {
      font-size: 60px;
      line-height: 1;
    }
    .projects__detail__item {
      gap: 10px;
    }
    .projects__detail__descripe span {
      font-size: 30px;
    }
    .projects__detail__descripe h4 {
      font-size: 18px;
    }
  }
  @media (max-width: 768px) {
    .projects {
      padding-top: 40px;
    }
    .projects__content__title {
      font-size: 32px;
    }
  }
}
</style>
