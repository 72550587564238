<template>
  <div class="opportunities">
    <div class="opportunities__content">{{ similarOpportunities.title }}</div>
    <div class="opportunities__container">
      <div class="opportunities__items">
        <a
          :href="`/recruitment/${item.id}`"
          class="opportunities__item"
          v-for="item in data"
          :key="item.id"
        >
          <div class="opportunities__item--title">
            <h3>{{ item.name }}</h3>
            <p v-if="!!job.number_experience">{{ item.number_experience }}</p>
          </div>
          <div class="opportunities__item--sub">
            <div class="d-flex align-items-center">
              <img :src="moneyIcon" alt="money" />
              <span class="ml-2">{{ item.salary }}</span>
            </div>
            <a :href="`/recruitment/${item.id}`">
              <div class="opportunities__item--btn">
                <svg
                  width="20"
                  height="16"
                  viewBox="0 0 20 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.33301 7C0.780723 7 0.333008 7.44772 0.333008 8C0.333008 8.55228 0.780723 9 1.33301 9V7ZM19.0123 8.70711C19.4029 8.31658 19.4029 7.68342 19.0123 7.29289L12.6484 0.928932C12.2579 0.538408 11.6247 0.538408 11.2342 0.928932C10.8436 1.31946 10.8436 1.95262 11.2342 2.34315L16.891 8L11.2342 13.6569C10.8436 14.0474 10.8436 14.6805 11.2342 15.0711C11.6247 15.4616 12.2579 15.4616 12.6484 15.0711L19.0123 8.70711ZM1.33301 9H18.3052V7H1.33301V9Z"
                    fill="white"
                  />
                </svg></div
            ></a></div
        ></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Opportunities',
  data() {
    return {
      moneyIcon: '/images/icons/recruitment/money.svg',
      arrowRightIcon: '/images/icons/home/arrow-right.svg',
      similarOpportunities:
        this.$t('recruiment').detailJobs.similarOpportunities,
      job: this.$t('recruiment').detailJobs.similarOpportunities.cards
    };
  },
  methods: {},
  watch: {
    lang() {
      this.similarOpportunities =
        this.$t('recruiment').detailJobs.similarOpportunities;
      this.job = this.$t('recruiment').detailJobs.similarOpportunities.cards;
    }
  },
  props: {
    data: Array
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  }
};
</script>

<style lang="scss" scroped>
.opportunities {
  width: 100%;
  padding: 120px 0;
  background: linear-gradient(
    0deg,
    #fff4ed -4.13%,
    rgba(255, 244, 237, 0.941811) 2.76%,
    rgba(255, 244, 237, 0) 114.26%
  );
  .opportunities__content {
    width: 80%;
    margin: auto;
    font-weight: 700;
    font-size: 40px;
    color: var(--blue-text);
    margin-bottom: 32px;
  }
  .opportunities__container {
    position: relative;
    width: 100%;
    background-color: #fdebe1;
    padding: 32px 0;
    &::after {
      position: absolute;
      content: '';
      bottom: -21px;
      left: 56px;
      background: linear-gradient(180deg, #ff6e26 0%, #ffe6d8 100%);
      width: 50px;
      height: 50px;
      border-radius: 50%;
      transform: rotate(-30deg);
    }
    &::before {
      position: absolute;
      content: '';
      top: -118px;
      right: 56px;
      background: #ffeae0;
      width: 98px;
      height: 98px;
      border-radius: 50%;
      transform: rotate(-30deg);
      z-index: -1;
    }
  }
  .opportunities__items {
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 70px;
  }
  .opportunities__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 260px;
    padding: 36px 30px 28px;
    border-radius: 25px;
    background: var(--white);
    transition: all 0.3s;
    overflow: hidden;
    &:hover {
      text-decoration: none;
    }
    &::after {
      content: '';
      width: 300px;
      height: 150px;
      background: url('/images/bg/circle-element.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      bottom: 0;
      left: -200px;
      transition: all 0.3s;
    }
    &::before {
      content: '';
      width: 300px;
      height: 150px;
      background: url('/images/bg/circle-element.png');
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      position: absolute;
      top: 0;
      right: -200px;
      transition: all 0.3s;
    }
    &:hover {
      background: rgba(252, 119, 53, 1);
      h3 {
        color: var(--white);
      }
      span {
        color: var(--white);
      }
      p {
        color: var(--orange);
        background-color: #fff;
      }
      &::before {
        right: -50px;
      }
      &::after {
        left: -50px;
      }
      .opportunities__item--btn {
        background-color: #fff;
        path {
          fill: var(--orange);
        }
      }
    }
  }
  .opportunities__item--title {
    gap: 16px;
    h3 {
      color: var(--black-text);
      font-weight: 700;
      font-size: 24px;
    }
    & > p {
      display: inline-block;
      margin-top: 5px;
      padding: 4px 10px;
      font-size: 18px;
      color: #088cfc;
      background-color: #e4f4ff;
      border-radius: 10px;
    }
  }
  .opportunities__item--sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    & > span {
      color: var(--grey-text);
      font-weight: 700;
      font-size: 18px;
    }
  }
  .opportunities__item--btn {
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    width: 52px;
    height: 52px;
    background-color: var(--blue-text);
    border-radius: 50%;
    cursor: pointer;
  }
  @media (max-width: 1024px) {
    .opportunities__items {
      gap: 30px;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .opportunities__content {
      font-size: 32px;
      text-align: center;
    }
  }
  @media (max-width: 768px) {
    .opportunities__items {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .opportunities__content {
      font-size: 32px;
      text-align: center;
    }
  }
}
</style>
