<template>
  <div class="benefit-package">
    <div class="benefit-package__container">
      <div
        class="benefit-package__items"
        v-for="(item, index) in data"
        :key="index"
      >
        <div v-if="item.img !== null" class="benefit-package__item__images">
          <img :src="item.img" alt="item" />
        </div>
        <div
          v-if="item.sub === null && item.title !== null"
          class="benefit-package__item--title"
        >
          {{ item.title }}
        </div>
        <div
          v-if="item.sub !== null && item.title !== null"
          class="benefit-package__item"
          :style="{ background: item.colorBg }"
        >
          <div class="benefit-package__item--title">{{ item.title }}</div>
          <ul class="benefit-package__item--content">
            <li v-for="(sub, indx) in item.sub" :key="indx">{{ sub }}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Benefit-package',
  data() {
    return {
      benefitPackage: this.$t('recruiment').detailJobs.benefitPackage,
      data: [
        {
          img: null
        },
        {
          img: null,
          colorBg: '#fee3d3'
        },
        {
          img: null,
          colorBg: '#e9f5ff'
        },
        { img: '/images/recruitment/benefit1.jpg' },
        {
          img: null,
          colorBg: '#e9f5ff'
        },
        {
          img: null,
          colorBg: '#fee3d3'
        },
        { img: '/images/recruitment/benefit2.jpg' },
        { img: '/images/recruitment/benefit3.jpg' },
        {
          img: null,
          colorBg: '#e9f5ff'
        }
      ]
    };
  },
  mounted() {
    this.data = this.data.map((item, indx) => ({
      ...item,
      title: this.benefitPackage[indx].title,
      sub: this.benefitPackage[indx].sub
    }));
  },
  watch: {
    lang() {
      this.benefitPackage = this.$t('recruiment').detailJobs.benefitPackage;
      this.data = this.data.map((item, indx) => ({
        ...item,
        title: this.benefitPackage[indx].title,
        sub: this.benefitPackage[indx].sub
      }));
    }
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang
    })
  }
};
</script>

<style lang="scss" scroped>
.benefit-package {
  position: relative;
  width: 100%;
  padding: 120px 0;
  background: linear-gradient(
    0deg,
    #fff4ed -4.13%,
    rgba(255, 244, 237, 0.941811) 2.76%,
    rgba(255, 244, 237, 0) 114.26%
  );
  z-index: 0;
  &::after {
    content: '';
    position: absolute;
    top: 115px;
    left: 0;
    width: 98%;
    height: 84%;
    background: url('/images/bg/background.png');
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
  }
  .benefit-package__container {
    width: 80%;
    margin: auto;
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 20px;
  }
  .benefit-package__items {
    max-height: 280px;
    & > .benefit-package__item--title {
      font-weight: 700;
      font-size: 40px;
      color: var(--blue-text);
    }
  }
  .benefit-package__item__images {
    width: 100%;
    height: 100%;
    & > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }
  .benefit-package__item {
    width: 100%;
    height: 100%;
    padding: 26px;
    border-radius: 10px;
  }
  .benefit-package__item--title {
    font-weight: 700;
    font-size: 20px;
    color: var(--blue-dark);
    margin-bottom: 10px;
  }
  .benefit-package__item--content {
    padding-left: 18px;
    li {
      font-weight: 400;
      font-size: 18px;
      color: var(--grey-text);
      margin-bottom: 6px;
      line-height: 1.42;
    }
  }
  @media (max-width: 1024px) {
    .benefit-package__container {
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .benefit-package__items {
      max-height: 100%;
    }
    .benefit-package__items:nth-child(4) {
      .benefit-package__item__images {
        height: 100%;
        margin-top: 0;
      }
    }
  }
  @media (max-width: 640px) {
    .benefit-package__item--title {
      text-align: center;
    }
    .benefit-package__container {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
    .benefit-package__items > .benefit-package__item--title {
      font-size: 32px;
    }
  }
}
</style>
