<template>
  <div class="detailproject__container">
    <BannerProjectDetail :data="project" />
    <ContentProjectDetail :data="project" />
    <ListOrtherProject :data="item_filter.slice(0, 12)" />
    <Footer />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import BannerProjectDetail from './Renew/Project/BannerProjectDetail.vue';
import ContentProjectDetail from './Renew/Project/ContentProjectDetail.vue';
import ListOrtherProject from './Renew/Project/ListOrtherProject.vue';

const Footer = () => import('../views/FooterNew.vue');

export default {
  name: 'ProjectDetails',
  data() {
    return {
      project_detail: this.$t('project_detail'),
      content: null,
      currentRoute: 0,
      value: '',
      project: {},
      item_filter: [],
    };
  },
  components: {
    Footer,
    BannerProjectDetail,
    ContentProjectDetail,
    ListOrtherProject,
  },
  created() {},
  mounted() {
    this.currentRoute = this.$route.params.id;
    this.project = this.project_detail.listProjectDetails.filter(
      (project) => project.id.toString() === this.currentRoute
    )[0];
    this.item_filter = this.project_detail.listProjectDetails.filter(
      (project) => project.id.toString() !== this.currentRoute
    );
  },
  metaInfo() {
    return {
      title: this.project.title + ' | TGL Solutions - Smart & Speed Solution',
      meta: [
        {
          name: 'description',
          content: this.project.message,
        },
        {
          property: 'og:title',
          content:
            this.project.title + ' | TGL Solutions - Smart & Speed Solution',
        },
        {
          property: 'og:description',
          content: this.project.message,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      lang: (state) => state.langs.lang,
      page: (state) => state.page.page,
    }),
  },
  watch: {
    lang() {
      this.project_detail = this.$t('project_detail');
      this.project = this.project_detail.listProjectDetails.filter(
        (project) => project.id.toString() === this.currentRoute
      )[0];
      this.item_filter = this.project_detail.listProjectDetails.filter(
        (project) => project.id.toString() !== this.currentRoute
      );
    },
    $route() {
      this.currentRoute = this.$route.params.id;
      this.project = this.project_detail.listProjectDetails.filter(
        (project) => project.id.toString() === this.currentRoute
      )[0];
    },
  },
  methods: {
    handleChangeIdProject(value) {
      this.currentRoute = value;
    },
    handleOnBack() {
      if (this.page === 'home') {
        this.$router.push('/');
      } else {
        this.$router.push('/projects');
      }
    },
    handleChangeInput() {
      this.item_filter = this.project_detail.listProjectDetails.filter(
        (item) => {
          return (
            item.title.toLowerCase().indexOf(this.value.toLowerCase()) > -1
          );
        }
      );
    },
  },
};
</script>

<style scoped>
.detailproject__container {
  width: 100%;
  margin: 0 auto;
}
.main-project-detail {
  display: none;
}
.content-project {
  margin-top: -22px !important;
}
@media (max-width: 500px) {
  .content-project {
    margin-top: -30px !important;
  }
}

.form-control:focus {
  box-shadow: none !important;
}
.form-control:hover {
  border: 1px solid #ced4da !important;
}
.btn-outline-secondary:focus {
  box-shadow: none !important;
}

.title {
  font-size: 1.25rem !important;
}
.main-project-detail {
  padding-top: 100px !important;
}
@media (max-width: 992px) {
  .main-project-detail {
    padding-top: 80px !important;
  }
}
.paragraph {
  font-size: 18px;
  color: #000000;
  font-weight: 500;
}
.sentence {
  font-size: 18px;
  color: #444444;
}
</style>
